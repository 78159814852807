import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import RoundButton from "../../UI/RoundButton";
import classes from './OnlineMeetingHomePage.module.css';
import ManQuizImage from "../../../media/HomePageResources/undrawImages/ManWithTasks.png";
import FemaleQuizImage from "../../../media/HomePageResources/undrawImages/WomanWithTasks.png";


const OnlineMeetingHomePage = () => {
   const [currentIndex, setCurrentIndex] = useState(0);
   const { t } = useTranslation();
   // Outside of the function this creates a error
   let ONLINE_SECTION_IMAGES = [ManQuizImage, FemaleQuizImage];

   useEffect(() => {
      const interval = setInterval(() => {
         setCurrentIndex((prevIndex) => (prevIndex + 1) % ONLINE_SECTION_IMAGES.length);
      }, 5000); // Should be adjusted with CSS

      return () => clearInterval(interval);
   }, [ONLINE_SECTION_IMAGES.length]);

   return (
      <section className={classes.engagment_section} >
         <h3 className={classes.engagment_title} >{t('home.meeting.title')}</h3>{/* Don't change to component */}
         <div className={classes.engagment_div}>
            <p className={classes.engagment_description} >{t('home.meeting.text.1')}<br />
            {t('home.meeting.text.2')}<br />
            {t('home.meeting.text.3')}
            </p>
            <div className={classes.image_button_div}>
               <img className={classes.engagment_img} src={ONLINE_SECTION_IMAGES[currentIndex]} thekey={1} alt={"Contact us"} />
               <RoundButton className={classes.engagment_button} innerText={t('home.meeting.button')} hasBlueBorder={true} linkTo={'/quiz'} />
            </div>
         </div>
      </section>
   )
}
export default OnlineMeetingHomePage;