import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import RecommendingPerson from '../../UI/RecommendingPerson';
import RoundButton from '../../UI/RoundButton';
import TitleH2Decoration from '../../UI/TitleH2Decoration';
import classes from './RecomndationHomePage.module.css';
import CreateRecommendationsTable from '../../../data/english strings/Recommandations_en';


const RecomndationHomePage = () => {
   const { t } = useTranslation();

   const recommendationsTable = CreateRecommendationsTable();

   const getRandomRecommendations = () => {
      // Shuffle the recommendations array to get random elements
      const shuffledRecommendations = recommendationsTable.sort(() => 0.5 - Math.random());

      // Return the first 3 elements (or fewer if the array has less than 3 elements)
      return shuffledRecommendations.slice(0, 3);
   };


   // Iterate through the recommndations
   const loopingRecemendations = () => {

      return (
         <Fragment>
            {getRandomRecommendations().map((recommendation) => (
               <RecommendingPerson
                  key={recommendation.id}
                  user_words={recommendation.words}
                  user_name={recommendation.name}
                  user_image={recommendation.picture}
               />
            ))}
         </Fragment>
      );
   };

   return (
      <section className={classes.recommendetions_section}>
         <TitleH2Decoration innerText={t('home.recommendations.title')} titleTag='h3' />
         <div className={classes.recommendetions_container}>
            {loopingRecemendations()}
         </div>
         <RoundButton innerText={t('home.recommendations.button')} hasBlueBorder={true} linkTo={'/what-people-say'} />
      </section>
   )
}

export default RecomndationHomePage;