import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import TitleH2Decoration from '../../UI/TitleH2Decoration';
import BiggerImage from '../../UI/BiggerImage';
import RoundButton from '../../UI/RoundButton';
import classes from './TreatmentsHomePage.module.css';
import CreateTreatmentsTable from '../../../data/english strings/Treatments_en';

const TreatmentsHomePage = () => {
   const { t } = useTranslation();

   const treatmentsTable = CreateTreatmentsTable();


   // Iterate through the recommndations
   const loopingTreatments = () => {
      return (
         <Fragment>
            {treatmentsTable.map((treatment) => (
               <figure className={classes.treatment_div} key={treatment.id}>
                  <BiggerImage className={classes.treatment_img} src={treatment.image} alt={treatment.title} />
                  <div className={classes.treatment_text_div}>
                     <TitleH2Decoration innerText={treatment.title} />
                     <figcaption className={classes.treatment_description}>
                        <p>{treatment.description}</p>
                     </figcaption>
                  </div>
               </figure>
            ))}
         </Fragment >
      );
   };

   return (
      <section className={classes.treatments_section}>
         <h3 className={classes.treatments_title}>{t('home.treatments.title')}</h3>
         <div className={classes.treatments_container}>
            {loopingTreatments()}
         </div>
         <RoundButton innerText={t('home.treatments.button')} hasBlueBorder={false} linkTo={'/awesome-services'} />
      </section>
   )
}

export default TreatmentsHomePage;