import React from "react";
import classes from "./NavigationBurger.module.css";


const NavigationBurger = (props) => {

   const burgerClasses = !props.navigationStatus ? classes.burger_div : `${classes.burger_div} ${classes.burger_div_clicked}`;

   return (
         <div className={burgerClasses} onClick={props.handleBurgerClick} >
            <span></span>
            <span></span>
            <span></span>
         </div>
   );
};

export default NavigationBurger;
