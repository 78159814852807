import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from "react-router-dom";
import classes from './MainFooter.module.css';
import cosmiCareLogo from "../../media/GeneralLogos/Shira's CosmiCare/CosmiCare_Logo_white.png";

const googleMapsLink = 'https://www.google.com/maps/place/CosmiCare+Berlin,+Cosmetics+Distributor/@52.5087689,13.4553784,15z/data=!4m6!3m5!1s0x47a84f96a102c22f:0xcaa59ebd0e94cc59!8m2!3d52.5087689!4d13.4553784!16s%2Fg%2F11v0vpmtb2?entry=ttu'

const MainFooter = () => {
   const { t } = useTranslation()
   
   return (
      <footer className={classes.main_footer}>
         <div className={classes.footer_center_div}>
            <div className={classes.footer_contact_div}>
               <a href="mailto:shira.lee6@gmail.com" target="_blank" rel="noreferrer">
                  <p>shira.lee6@gmail.com</p>
               </a>
               <a href="https://wa.me/+4915256837527" target="_blank" rel="noreferrer">
                  <p>+49-152-56837527</p>
               </a>
               <a href={googleMapsLink} target="_blank" rel="noreferrer">
                  <p>Simon-Dace-Straße 20 10245</p>
               </a>
            </div>

            {/* SITEMAP LINKS */}
            <div className={classes.footer_sitemap}>
               <NavLink className={classes.active} to="/"><p>{t('navbar.main.home')}</p></NavLink>
               <NavLink className={classes.active} to="/see-for-yourself"><p>{t('navbar.main.gallery')}</p></NavLink>
               <NavLink className={classes.active} to="/what-people-say"><p>{t('navbar.main.recommendations')}</p></NavLink>
            </div>

            <div className={classes.footer_sitemap}>
               <NavLink className={classes.active} to="/about-me"><p>{t('navbar.main.about')}</p></NavLink>
               <NavLink className={classes.active} to="/awesome-services"><p>{t('navbar.main.services')}</p></NavLink>
               <NavLink className={classes.active} to="/great-products"><p>{t('navbar.main.products')}</p></NavLink>
            </div>

            <div className={classes.footer_sitemap}>
               <NavLink className={classes.active}  to="/quiz"><p>{t('navbar.main.quiz')}</p></NavLink>
               <NavLink className={classes.active}  to="/contact-me"><p>{t('navbar.main.contact')}</p></NavLink>
               <NavLink className={classes.active}  to="/bureaucracy-related"><p>{t('navbar.main.impressum')}</p></NavLink>
            </div>
         </div> {/* footer_center_div */}
         
         <div className={classes.footer_images_div}>
            <img className={classes.footer_center_logo} src={cosmiCareLogo} alt='CosmiCare Logo' />
         </div>
         <p className={classes.copyright_footer}>&copy; {new Date().getFullYear()}
            &nbsp;All rights reserved to Shira Lee CosmiCare. &#58;&#41;&nbsp;
         </p>
         {/* TODO: START HERE */}

      </footer>
   );
};

export default MainFooter;