// eslint-disable-next-line
import React from "react";
import { useTranslation } from "react-i18next";
import ShayProfilePicture from "../../media/Testimonials/Photo recommendations/Shay Yacinton Ariely/facebook_1689162528353_7084861149322868770.jpg";
import NitsanProfilePicture from "../../media/Testimonials/Photo recommendations/Nitsan Bernstein/facebook_1689161757077_7084857914357135715.jpg";
import SapirHProfilePicture from "../../media/Testimonials/Photo recommendations/Sapir Huberman/facebook_1689161979146_7084858845781451898.jpg";
import ShaniProfilePicture from "../../media/Testimonials/Photo recommendations/Shani Roz Elbaz/facebook_1689162397959_7084860602411753170.jpg";
import ElenaProfilePicture from "../../media/Testimonials/Photo recommendations/Elena Portman/facebook_1689158458964_7084844081069869243.jpg";
import SapirMProfilePicture from "../../media/Testimonials/Photo recommendations/Sapir Melamed/facebook_1690662904318_7091154182236213947.jpg";
import YonatanProfilePicture from "../../media/Testimonials/Photo recommendations/Yonatan Zahavi/facebook_1689162635885_7084861600344669969.jpg";
import DanielleProfilePicture from "../../media/Testimonials/Photo recommendations/Danielle Dandy/facebook_1689163923843_7084867002432091505.jpg"
import TalAshtalgaProfilePicture from "../../media/Testimonials/Photo recommendations/Tal Ashtalga Yoga/facebook_1689160262645_7084851646255646142.jpg";
import YearitProfilePicture from "../../media/Testimonials/Photo recommendations/Yearit Abaraham/facebook_1689163155955_7084863781678725749.jpg";
import MariaProfilePicture from "../../media/Testimonials/Photo recommendations/Maria Alexander/facebook_1689163251524_7084864182522507953.jpg";
import GiliProfilePicture from "../../media/Testimonials/Photo recommendations/Gili Ron/facebook_1689163311073_7084864432287571962.jpg";
import NatalieProfilePicture from "../../media/Testimonials/Photo recommendations/Natali Gluck/facebook_1689163430036_7084864931255478526.jpg";
import AlinaProfilePicture from "../../media/Testimonials/Photo recommendations/Alina Krichevsky/facebook_1689163476485_7084865126075508251.jpg";
import MeitalProfilePicture from "../../media/Testimonials/Photo recommendations/Meital Madar/facebook_1689163520536_7084865310838907030.jpg";
import LimorProfilePicture from "../../media/Testimonials/Photo recommendations/Limor Berman Harms/facebook_1689163564643_7084865495836456780.jpg";
import OsnatProfilePicture from "../../media/Testimonials/Photo recommendations/Osnat Nir/facebook_1689163617310_7084865716741383437.jpg";
import EdenProfilePicture from "../../media/Testimonials/Photo recommendations/Eden Lev/facebook_1689163773528_7084866371965507518.jpg";
import LinorProfilePicture from "../../media/Testimonials/Photo recommendations/Linor Yaa/facebook_1689163823145_7084866580072550852.jpg";
import TalNataleeShifrinProfilePicture from "../../media/Testimonials/Photo recommendations/Tal Natalie Shifrin/facebook_1689160103693_7084850979561657559.jpg";
import NaomiShaProfilePicture from "../../media/Testimonials/Photo recommendations/Naomi Sha/facebook_1689161837252_7084858250634542609.jpg";
import ZubkovVeronicaProfilePicture from "../../media/Testimonials/Photo recommendations/Zubkov Veronica/facebook_1689160165044_7084851236885178767.jpg";
import LorielSherProfilePicture from "../../media/Testimonials/Photo recommendations/Loriel Sher/facebook_1689159010506_7084846394405386677.jpg";
import HadarDariGevaProfilePicture from "../../media/Testimonials/Photo recommendations/Hadar Dari Geva/facebook_1689158924428_7084846033367703993.jpg";
import SoniaCashProfilePicture from "../../media/Testimonials/Photo recommendations/Sonia Cash/facebook_1689160209853_7084851424829924678.jpg";
import LycheeBernsteinProfilePicture from "../../media/Testimonials/Photo recommendations/Lychee Bernstein/facebook_1689158349145_7084843620455067371.jpg";
import RachelRingartProfilePicture from "../../media/Testimonials/Photo recommendations/Rachel Ringart/facebook_1689163011896_7084863177451140368.jpg";
import OfriLebermanProfilePicture from "../../media/Testimonials/Photo recommendations/Ofri Leberman/facebook_1689163080154_7084863463742322071.jpg";

const CreateRecommendationsTable = () => {
   const { t } = useTranslation();

   const recommendationsTable = [
      {
         id: 1,
         words: t('testimonial.ariely.words'),
         name: t('testimonial.ariely.title'),
         picture: ShayProfilePicture,
      },
      {
         id: 2,
         words: t('testimonial.nitsan.words'),
         name: t('testimonial.nitsan.title'),
         picture: NitsanProfilePicture,
      },
      
      // Testimonial 2
      {
         id: 3,
         words: t('testimonial.sapir.words'),
         name: t('testimonial.sapir.title'),
         picture: SapirHProfilePicture,
      },
      
      // Testimonial 3
      {
         id: 4,
         words: t('testimonial.shani.words'),
         name: t('testimonial.shani.title'),
         picture: ShaniProfilePicture,
      },
      {
         id: 5,
         words: t('testimonial.elena.words'),
         name: t('testimonial.elena.title'),
         picture: ElenaProfilePicture,
      },
      {
         id: 6,
         words: t('testimonial.loriel.words'),
         name: t('testimonial.loriel.title'),
         picture: LorielSherProfilePicture,
      },
      {
         id: 7,
         words: t('testimonial.yonatan.words'),
         name: t('testimonial.yonatan.title'),
         picture: YonatanProfilePicture,
      },
      {
         id: 8,
         words: t('testimonial.tal.words'),
         name: t('testimonial.tal.title'),
         picture: TalAshtalgaProfilePicture,
      },
      {
         id: 9,
         words: t('testimonial.yearit.words'),
         name: t('testimonial.yearit.title'),
         picture: YearitProfilePicture,
      },
      {
         id: 10,
         words: t('testimonial.maria.words'),
         name: t('testimonial.maria.title'),
         picture: MariaProfilePicture,
      },
      {
         id: 11,
         words: t('testimonial.gili.words'),
         name: t('testimonial.gili.title'),
         picture: GiliProfilePicture,
      },
      {
         id: 12,
         words: t('testimonial.natalie.words'),
         name: t('testimonial.natalie.title'),
         picture: NatalieProfilePicture,
      },
      {
         id: 13,
         words: t('testimonial.alina.words'),
         name: t('testimonial.alina.title'),
         picture: AlinaProfilePicture,
      },
      {
         id: 14,
         words: t('testimonial.meital.words'),
         name: t('testimonial.meital.title'),
         picture: MeitalProfilePicture,
      },
      {
         id: 15,
         words: t('testimonial.limor.words'),
         name: t('testimonial.limor.title'),
         picture: LimorProfilePicture,
      },
      {
         id: 16,
         words: t('testimonial.osnat.words'),
         name: t('testimonial.osnat.title'),
         picture: OsnatProfilePicture,
      },
      {
         id: 17,
         words: t('testimonial.eden.words'),
         name: t('testimonial.eden.title'),
         picture: EdenProfilePicture,
      },
      {
         id: 18,
         words: t('testimonial.linor.words'),
         name: t('testimonial.linor.title'),
         picture: LinorProfilePicture,
      },
      {
         id: 19,
         words: t('testimonial.sapirm.words'),
         name: t('testimonial.sapirm.title'),
         picture: SapirMProfilePicture,
      },
      {
         id: 20,
         words: t('testimonial.dandy.words'),
         name: t('testimonial.dandy.title'),
         picture: DanielleProfilePicture,
      },
      {
         id: 21,
         words: t('testimonial.naomi.words'),
         name: t('testimonial.naomi.title'),
         picture: NaomiShaProfilePicture,
      },
      {
         id: 22,
         words: t('testimonial.zubkov.words'),
         name: t('testimonial.zubkov.title'),
         picture: ZubkovVeronicaProfilePicture,
      },
      {
         id: 23,
         words: t('testimonial.taln.words'),
         name: t('testimonial.taln.title'),
         picture: TalNataleeShifrinProfilePicture,
      },
      {
         id: 24,
         words: t('testimonial.hadar.words'),
         name: t('testimonial.hadar.title'),
         picture: HadarDariGevaProfilePicture,
      },
      {
         id: 25,
         words: t('testimonial.sonia.words'),
         name: t('testimonial.sonia.title'),
         picture: SoniaCashProfilePicture,
      },
      {
         id: 26,
         words: t('testimonial.lychee.words'),
         name: t('testimonial.lychee.title'),
         picture: LycheeBernsteinProfilePicture,
      },
      {
         id: 27,
         words: t('testimonial.rachel.words'),
         name: t('testimonial.rachel.title'),
         picture: RachelRingartProfilePicture,
      },
      {
         id: 28,
         words: t('testimonial.ofri.words'),
         name: t('testimonial.ofri.title'),
         picture: OfriLebermanProfilePicture,
      },
   ];
   
   return recommendationsTable;
};

export default CreateRecommendationsTable;