// eslint-disable-next-line
import React from 'react';
import { useTranslation } from 'react-i18next';
import pigmantationImage from '../../media/Treatments/Pigmentation treatments_.jpg';
import antiAgingImage from '../../media/Treatments/Anti-aging.jpg';
import acneImage from '../../media/Treatments/Acne.jpg';
import goldCombeImage from '../../media/Treatments/Gold combi treatment_.jpg';
import rosacaePicture from '../../media/Treatments/rosacea.jpg';

const CreateTreatmentsTable = () => {
   const { t } = useTranslation();

   const treatmentsTable = [
      {
         id: 1,
         image: pigmantationImage,
         title: t('home.treatment.1.title'),
         description: t('home.treatment.1.description')
      },
      {
         id: 2,
         image: goldCombeImage,
         title: t('home.treatment.2.title'),
         description: t('home.treatment.2.description')
      },
      {
         id: 3,
         image: antiAgingImage,
         title: t('home.treatment.3.title'),
         description: t('home.treatment.3.description')
      },
      {
         id: 4,
         image: rosacaePicture,
         title: t('home.treatment.4.title'),
         description: t('home.treatment.4.description')
      },
      {
         id: 5,
         image: acneImage,
         title: t('home.treatment.5.title'),
         description: t('home.treatment.5.description')
      },
   ];
   return treatmentsTable
};

export default CreateTreatmentsTable;