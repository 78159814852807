import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
   .use(Backend)
   .use(LanguageDetector)
   .use(initReactI18next) //useTranslation
   .init({
      partialBundledLanguages: true, // Allow lazy loading
      lng: 'en',
      ns: ['main_translations'],
      backend: {
         loadPath: 'assets/locales/{{lng}}/{{ns}}.json', // Adjust this path as needed.
      },
      fallbackLng: 'en',
      debug: false,
      keySeparator: false,
      interpolation: {
         escapeValue: false,
         formatSeparator: ','
      },
   });

export default i18n;
