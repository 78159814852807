import React, { Fragment } from "react";
import ReactDOM from "react-dom";

import NavigationBar from "./NavigationBar";
import MobileLayover from "./MobileLayover";

const GeneralLayout = (props) => {
   const mobileLayoverContainer = document.getElementById('mobile-layover');

   return (
      <Fragment>
         {ReactDOM.createPortal(<MobileLayover />, mobileLayoverContainer)}
         <NavigationBar />
         <main>{props.children}</main>
      </Fragment>
   );
};

export default GeneralLayout;
