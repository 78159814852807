import React from 'react'
import { useTranslation } from 'react-i18next';
import RoundButton from '../../UI/RoundButton';
import BiggerImage from '../../UI/BiggerImage';
import classes from './ServicesHomePage.module.css';

import CreateServiceTable from '../../../data/english strings/Services_en';

const ServicesHomePage = () => {
   const { t } = useTranslation();
   const serviceTable = CreateServiceTable();

   return (
      <section className={classes.services_section}>
         <h3 className={classes.services_title}>{t('home.services.title')}</h3> {/* Don't change to component */}
         <div className={classes.services_div}>
            {/* Looping the data */}
            {serviceTable.map((service, index) => (
               <div className={classes.service_div} key={index}>
                  <BiggerImage className={classes.service_img} src={service.imageSrc} alt={service.alt} />
                  <figcaption className={classes.service_title}><h2>{service.title}</h2></figcaption>
                  <p className={classes.service_description}>{service.describe}</p>
               </div>
            ))}
         </div>
         <div className={classes.aligning_button}>
            <RoundButton innerText={t('home.services.button')} hasBlueBorder={false} linkTo={'/awesome-services'} />
         </div>
      </section>
   );
};


export default ServicesHomePage;