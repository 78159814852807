import React from "react";
import { useTranslation } from "react-i18next";
import RoundButton from "../../UI/RoundButton";
import classes from "./InstagramHomePage.module.css";
import instagramLogo from '../../../media/GeneralLogos/InstagramTextLogo.png';
import INSTAPOSTS from "../../../data/english strings/Instagram_en";

const InstagramHomePage = () => {
   const { t } = useTranslation();

   // Iterate the links
   const loopTheLinks = () => {
      return INSTAPOSTS.map((post) => (
         <a target="_blank" rel="noreferrer" href={post.link} key={post.id} className={classes.instagram_item}>
            <img src={post.imageSrc} alt="Instagram Post" />
         </a>
      ))
   }

   return (
      <section className={classes.instagram_section}>
         <div className={classes.instagram_title_div}>
            <h3>{t('home.instagram.title')}</h3>
            <img className={classes.instagram_logo} src={instagramLogo} alt="Instagram logo" />
         </div>
         <p>{t('home.instagram.text')}</p>
         <div className={classes.instagram_post_container}>
            {loopTheLinks()}
         </div>
         <div id='alighning_button_to_the_center_without_css_to_fix_the_problem_div'>
            <RoundButton innerText={t('home.instagram.button')} linkTo="https://www.instagram.com/shira_lee_cosmicare/"/>
         </div>
      </section>
   );
};

export default InstagramHomePage;