import React, { useState, Fragment } from 'react';
import classes from './BiggerImage.module.css';

// For technical resaons, thekey and not theKey
const BiggerImage = ({ zoom = true, src, className, thekey, alt }) => {
   const [isZoomed, setZoom] = useState(false)

   const toggleFlag = () => {
      setZoom((state) => !state)
   }

   // If one can't enlarge the picture
   if (!zoom) return <img src={src} className={className} alt={alt} key={thekey} />

   else
      return (
         <Fragment >
            {isZoomed ? (
               <figure onClick={toggleFlag} className={`${classes.lightbox} ${classes.show} relative`} >
                  <img className={classes.show_image} src={src} alt={alt} key={thekey} />
               </figure>
            ) : (
               // Consider: Visual effect to hint the user the image can be zoomed in.
               <figure>
                  <img onClick={toggleFlag} className={className} src={src} alt={alt} key={thekey} />
               </figure>
            )}
         </Fragment>
      )
}

export default BiggerImage;