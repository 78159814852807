import React, { useState, Suspense, Fragment} from "react";
import { useTranslation } from 'react-i18next';
import LoadingSpinner from "./LoadingSpinner";
import classes from "./LanguageSelector.module.css";

const LanguageSelector = () => {
   const { t, i18n } = useTranslation();
   const [showingOtherLanguages, setShowingOtherLanguages] = useState(false);
   const i18nLanguage = i18n.language;

   const toggleLanguageSelector = () => {
      setShowingOtherLanguages(value => !value);
   };

   const changeLanguage = (event) => {
      i18n.changeLanguage(event.target.value);
      setShowingOtherLanguages(false);
      setHtmlAttributes(event.target.value);
   };

   const setHtmlAttributes = (lang) => {
      const html = document.documentElement;
      switch (lang) {
         case "he":
            html.setAttribute('dir', 'rtl');
            html.setAttribute('lang', 'he');
            break;
         case "de":
            html.setAttribute('dir', 'ltr');
            html.setAttribute('lang', 'de');
            break;
         default:
            html.setAttribute('dir', 'ltr');
            html.setAttribute('lang', 'en');
            break;
      }
   };

   const setCurrentLanguageString = (currentLanguageString) => {
      switch (currentLanguageString) {
         case "he":
            return "He";
         case "de":
            return "De";
         case "en":
            return "En";
         default:
            return "";
      }
   };

   return (
      <Fragment>
         {!showingOtherLanguages ? (
            <div onClick={toggleLanguageSelector}>
               <p>{setCurrentLanguageString(i18nLanguage)}</p>
            </div>
         ) : (
            <div onChange={changeLanguage} className={classes.language_radio} >
               <div className={classes.language_div} onClick={toggleLanguageSelector}>
                  <p>{t('common.motion.back')}</p>
               </div>
               <div className={classes.language_div}>
                  <input type="radio" value="en" name="language" id="english" />
                  <label htmlFor="english"><p>English</p></label>
               </div>
               <div className={classes.language_div}>
                  <input type="radio" value="de" name="language" id="german" />
                  <label htmlFor="german"><p>Deutsch</p></label>
               </div>
               <div className={classes.language_div}>
                  <input type="radio" value="he" name="language" id="hebrew" />
                  <label htmlFor="hebrew"><p>עברית</p></label>
               </div>
            </div>
         )}
      </Fragment>
   );
};

const LanguageSelectorWithSuspense = () => {
   return (
      <Suspense fallback={<LoadingSpinner />}>
         <LanguageSelector />
      </Suspense>
   );
};

export default LanguageSelectorWithSuspense;
