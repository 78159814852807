import React from 'react'
import { useTranslation } from 'react-i18next';
import RoundButton from '../../UI/RoundButton';
import BiggerImage from '../../UI/BiggerImage';
import classes from './ProductsHomePage.module.css';
import productsHomePageImage from '../../../media/Products/products on home page.png';

const ProductsHomePage = () => {
   const { t } = useTranslation();

   return (
      <section className={classes.products_section}>
         <BiggerImage className={classes.only_to_web} src={productsHomePageImage} thekey={1} alt={"Biofor products"} key={1} zoom={true} />
         <div className={classes.products_div}>
            <h3 className={classes.products_title}>{t('home.products.title')}</h3>{/* Don't change 2 component */}
            <p className={classes.products_description}>{t('home.products.text')}</p>
            <BiggerImage src={productsHomePageImage} thekey={1} alt={"Biofor products"} key={1} zoom={true} />
            <RoundButton innerText={t('home.products.button')} hasBlueBorder={true} linkTo={'/great-products'} />
         </div>
      </section>
   )
}

export default ProductsHomePage;