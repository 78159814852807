import React from 'react';
import classes from './TitleH2Decoration.module.css';

const applyClass = (wishedColor, styleType) => {
   const classMappings = {
      white: {
         h2: classes.white_underline,
         h3: classes.white_text,
      },
      black: {
         h2: classes.black_underline,
         h3: classes.black_text,
      },
      primary: {
         h2: classes.primary_underline,
         h3: classes.primary_text,
      },
   };

   return classMappings[wishedColor]?.[styleType] || '';
};

const TitleH2Decoration = ({ titleTag = 'h2', innerText = 'white', wishedColor = 'primary' }) => {
   const classForTag = applyClass(wishedColor, titleTag);
   const classForInnerText = applyClass(wishedColor, 'text');

   return (
      <div className={classes.main_component_div}>
         {React.createElement(
            titleTag,
            { className: `${classes.underlined_title} ${classForTag}`},
            <p className={classForInnerText}>{innerText}</p>
         )}
      </div>
   );
};

export default TitleH2Decoration;
