import React, { lazy, Suspense } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";

import GeneralLayout from "./components/layout/GeneralLayout";
import LoadingSpinner from "./components/UI/LoadingSpinner";
import './i18n';
import MainFooter from "./components/layout/MainFooter";


//Pages
import HomePage from './pages/HomePage';
const Gallery = lazy(() => import('./pages/Gallery'));
const Testimonials = lazy(() => import('./pages/Testimonials'));
const AboutMe = lazy(() => import('./pages/AboutMe'));
const Services = lazy(() => import('./pages/Services'));
const Products = lazy(() => import('./pages/Products'));
const Quiz = lazy(() => import('./pages/Quiz'));
const ContactUs = lazy(() => import('./pages/ContactUs'));
const PrivacyPolicy = lazy(() => import('./pages/PrivacyPolicy'));
const NoPageFound = lazy(() => import('./pages/NoPageFound'));

function App() {
   const { pathname } = useLocation();

   return (
      <GeneralLayout>
         <Suspense fallback={<div><LoadingSpinner /></div>}>
            <Switch>
               <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />

               {/* REDIRECTS */}
               <Route path="/" exact>
                  <Redirect to="/welcome" />
               </Route>
               <Route path=" " exact>
                  <Redirect to="/welcome" />
               </Route>

               {/* ROUTES */}
               <Route path="/welcome" exact>
                  <HomePage />
               </Route>
               <Route path="/see-for-yourself" exact>
                  <Gallery />
               </Route>
               <Route path="/what-people-say" exact>
                  <Testimonials />
               </Route>
               <Route path="/about-me" >
                  <AboutMe />
               </Route>
               <Route path="/awesome-services" exact>
                  <Services />
               </Route>
               <Route path="/great-products" exact>
                  <Products />
               </Route>
               <Route path="/quiz" exact>
                  <Quiz />
               </Route>
               <Route path="/contact-me" exact>
                  <ContactUs />
               </Route>
               <Route path="/bureaucracy-related" exact>
                  <PrivacyPolicy />
               </Route>

               {/* DEFAULT ROUTES */}
               <Route path="*">
                  <NoPageFound />
               </Route>
               {/* WEIRD ROUTES */}
            </Switch>
            <MainFooter />
         </Suspense>
      </GeneralLayout>
   );
}

export default App;
