import React from "react";
import StretchHeaderImage from "../components/UI/StretchHeaderImage";
import classes from "./HomePage.module.css";


// IMPORT IMAGES
import HomePageHeaderImage from "../media/HeaderImages/HomePageHeader.jpg";
import TopToBottomWave from "../media/Background/ShortFromTopPinkish.png";
import ProductsHomePage from "../components/layout/homepage/ProductsHomePage";
import ServicesHomePage from "../components/layout/homepage/ServicesHomePage";
import RecomndationHomePage from "../components/layout/homepage/RecomndationHomePage";
import TreatmentsHomePage from "../components/layout/homepage/TreatmentsHomePage";
import OnlineMeetingHomePage from "../components/layout/homepage/OnlineMeetingHomePage";
import InstagramHomePage from "../components/layout/homepage/InstagramHomePage";

const HomePage = () => {
   return (
      <section>
         <StretchHeaderImage imageSource={HomePageHeaderImage} imageAlt={"beautiful face"} />
         <img className={classes.top_to_bottom_wave} src={TopToBottomWave} alt="Top to bottome wave" />
         <ProductsHomePage />
         <ServicesHomePage />
         <RecomndationHomePage />
         <TreatmentsHomePage />
         <OnlineMeetingHomePage />
         <InstagramHomePage />
      </section >
   )
}

export default HomePage;