import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import NavigationBurger from "./NavigationBurger";
import CreateNavigationTable from "../../data/english strings/Navigation_en";
import classes from './MobileNavigations.module.css';
import SOCIAL_LINKS from "../../data/english strings/Social_links_en.js";
import LanguageSelectorWithSuspense from "./LanguageSelector";

const MobileNavigations = () => {
   const [clickedBurger, setClickBurger] = useState(false);
   
   useEffect(() => { }, [clickedBurger]); // Log the updated state whenever it changes

   const navigationTable = CreateNavigationTable();

   const toggleBurgerHandler = () => {
      setClickBurger((click) => !click);
   };

   const sectionClasses = clickedBurger ? classes.cover_background : classes.mobile_overlay_section;

   return (
      <section className={sectionClasses}>
         <NavigationBurger navigationStatus={clickedBurger} handleBurgerClick={toggleBurgerHandler} />
         <div className={classes.footer_social_links}>
            {SOCIAL_LINKS.map((link, index) => (
               <a href={link.link} target="_blank" rel="noreferrer" key={index}>
                  <img
                     key={index}
                     className={classes.footer_social_image}
                     src={link.picture}
                     alt={link.alt}
                  />
               </a>
            ))}
         </div>
         <div className={classes.only_mobile}>
            <LanguageSelectorWithSuspense />
         </div>
         {clickedBurger && (
            <div className={classes.background_div}>
               <ul>
                  {navigationTable.map((link, index) => (
                     <li
                        key={index}
                        className={classes.navlink_list}>
                        <NavLink
                           key={index}
                           to={link.to}
                           activeClassName={classes.active}
                           onClick={() => toggleBurgerHandler()}
                        >
                           <h2>{link.text}</h2>
                        </NavLink>
                     </li>
                  ))}
               </ul>
            </div>
         )}
      </section>
   );
};

export default MobileNavigations;

