import React from "react";
import classes from "./RecommendingPerson.module.css";

import FiveStar from "../../media/Testimonials/FiveStar.png";

const RecommendingPerson = ({ user_image, user_name, user_words }) => {

   return (
      <div className={classes.recommending_background_div} >
         <div className={classes.recommending_div} >
            <img className={classes.recommending_v_img} src={FiveStar} alt="'V' sign" />
            <p className={classes.recommending_text} >
               &quot;{user_words}&quot;
            </p>
            <p className={classes.recommending_name} >~{user_name}~</p>
            <img className={classes.recommending_profile} src={user_image} alt='Recommending client profile' />
         </div>
      </div>
   )
}

export default RecommendingPerson;