import FacebookLogo from "../../media/GeneralLogos/facebook.png";
import InstagramLogo from "../../media/GeneralLogos/instagram.png";
import WhatsAppLogo from "../../media/GeneralLogos/whatsapp.png";
import EmailLogo from "../../media/GeneralLogos/email.png";
import YoutubeLogo from "../../media/GeneralLogos/youtube.png";
import CosmiCareLogo from "../../media/GeneralLogos/Shira's CosmiCare/OnlyStar.png";

const SOCIAL_LINKS = [
  {
    picture: WhatsAppLogo,
    alt: "What's app logo",
    link: "https://api.whatsapp.com/send/?phone=4915256837527",
  },
  {
    picture: FacebookLogo,
    alt: "Facebook logo",
    link: "https://www.facebook.com/cosmicareberlin",
  },
  {
    picture: InstagramLogo,
    alt: "Instagram logo",
    link: "https://www.instagram.com/shira_lee_cosmicare/",
  },
  {
    picture: EmailLogo,
    alt: "Email logo",
    link: "mailto:shira.lee6@gmail.com",
  },
  {
    picture: YoutubeLogo,
    alt: "Youtube logo",
    link: "https://www.youtube.com/@shiradotan",
  },
  {
    picture: CosmiCareLogo,
    alt: "CosmiCare logo",
    link: "https://www.shiraleecare.com/contact-us",
  },
];

export default SOCIAL_LINKS;
