import React, { Suspense } from "react";
import MobileNavigation from '../UI/MobileNavigations';


const MobileLayover = () => {
   return (
      <Suspense fallback="...">
         <MobileNavigation />
      </Suspense>
   )
}

export default MobileLayover;