// eslint-disable-next-line
import React from "react";
import { useTranslation } from "react-i18next";

const CreateNavigationTable = () => {
   const { t } = useTranslation();

   const navigationTable = [
      {
         text: t('navbar.main.home'),
         to: '/welcome',
      },
      {
         text: t('navbar.main.gallery'),
         to: '/see-for-yourself',
      },
      {
         text: t('navbar.main.recommendations'),
         to: '/what-people-say',
      },
      {
         text: t('navbar.main.about'),
         to: '/about-me',
      },
      {
         text: t('navbar.main.services'),
         to: '/awesome-services',
      },
      {
         text: t('navbar.main.products'),
         to: '/great-products',
      },
      {
         text: t('navbar.main.quiz'),
         to: '/quiz',
      },
      {
         text: t('navbar.main.contact'),
         to: '/contact-me',
      },
   ];
   return navigationTable;
};

export default CreateNavigationTable;