// eslint-disable-next-line
import React from 'react';
import instaImage1 from '../../media/Gallery/Instagram09.08.2023/1.jpeg';
import instaImage2 from '../../media/Gallery/Instagram09.08.2023/2.jpeg';
import instaImage3 from '../../media/Gallery/Instagram09.08.2023/3.jpeg';

const INSTAPOSTS = [
   {
      id: 1,
      link: 'https://www.instagram.com/p/CtjDlEStVxj/',
      imageSrc: instaImage1,
   },
   {
      id: 2,
      link: 'https://www.instagram.com/p/Cth9lFBtHbE/',
      imageSrc: instaImage2,
   },
   {
      id: 3,
      link: 'https://www.instagram.com/p/Ct1t6RhNG3N/',
      imageSrc: instaImage3,
   },
];

export default INSTAPOSTS;