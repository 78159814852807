import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import classes from "./RoundButton.module.css"

const RoundButton = ({ innerText, hasBlueBorder, linkTo, isInternalLink }) => {
   // <RoundButton innerText={} hasBlueBorder={} linkTo={} isInternalLink={} />
   const [blueBorder, setBlueBorder] = useState(hasBlueBorder);

   useEffect(() => {
      setBlueBorder(!!hasBlueBorder); // Convert to boolean
   }, [hasBlueBorder]);

   // Define background and border colors 
   const blueEdges = blueBorder ? classes.round_button : `${classes.white_round_button} ${classes.round_button}`;

   // Define if the link is external or internal
   const ButtonComponent = isInternalLink ? Link : 'a';
   const buttonProps = isInternalLink ? {
      to: linkTo
   } : {
      href: linkTo,
   };

   return (
      <ButtonComponent className={classes.button_link} {...buttonProps}>
         <div className={blueEdges}>
            <p className={classes.button_p}>{innerText}</p>
         </div>
      </ButtonComponent>
   )
}

export default RoundButton;
