import React, { useState, useEffect, Suspense } from 'react';
import { NavLink } from "react-router-dom";
import LanguageSelector from "../UI/LanguageSelector";
import LoadingSpinner from '../UI/LoadingSpinner';

import classes from "./NavigationBar.module.css";
import cosmiCareLogo from "../../media/GeneralLogos/Shira's CosmiCare/text_logo_bluish.png"
import CreateNavigationTable from "../../data/english strings/Navigation_en";

const LoopingNavigation = () => {

   const navigationTable = CreateNavigationTable();

   return (
      <ul className={classes.nav_ul}>
         <li className={classes.nav_language}>
            <LanguageSelector />
         </li>
         {navigationTable.map((link, index) => (
            <NavLink key={index} to={link.to} activeClassName={classes.active}>
               <li key={index}>
                  <p>{link.text}</p>
               </li>
            </NavLink>
         ))}
      </ul>
   );
};

const NavigationBar = () => {
   const [scrolled, setScrolled] = useState(false);

   useEffect(() => {
      const handleScroll = () => {
         setScrolled(window.scrollY > 1);
      };

      window.addEventListener('scroll', handleScroll);
      return () => {
         window.removeEventListener('scroll', handleScroll);
      };
   }, []);

   return (
      <header className={classes.header}>
         <div className={scrolled ? classes.gone_div : classes.logo_div}>
            <img src={cosmiCareLogo} alt="Shira's logo" />
         </div>
         <nav>
            <Suspense fallback={<LoadingSpinner/>}>
               <LoopingNavigation />
            </Suspense>
         </nav>
      </header>
   );
};

export default NavigationBar;
