// eslint-disable-next-line
import React from 'react';
import { useTranslation } from 'react-i18next';
import BlackheadsImage from '../../media/Services/BlackRemoval.jpg';
import MicroneedlingImage from '../../media/Services/MicroneedlingToRight.jpg';
import ClassicServiceImage from '../../media/Services/ClasicServiceHomePage.jpg';

const CreateServiceTable = () => {
   const { t } = useTranslation();
   const serviceTable = [
      {
         title: t('home.service.1.title'),
         imageSrc: MicroneedlingImage,
         alt: 'Microneedling',
         describe: t('home.service.1.text'),
      },
      {
         title: t('home.service.2.title'),
         imageSrc: BlackheadsImage,
         alt: 'Micro-needling Treatment',
         describe: t('home.service.2.text'),
      },
      {
         title: t('home.service.3.title'),
         imageSrc: ClassicServiceImage,
         alt: 'Classic Treatment',
         describe: t('home.service.3.text'),
      },
   ];

   return serviceTable;
};

export default CreateServiceTable;